@import "@/assets/sass/vendors/vue/vuetify/variables.scss";







































































.h-65 {
  height: 65px !important;
}
